import React, { FunctionComponent } from 'react';
import { UserType, useAppContext } from '../../context/appContext';
import { classNames } from '../../utilities/classNames';
import { DashboardComponent } from '../../typings/backend-types';
import { Permission } from '../../typings/roleConfig';
import useUserReadAccess from '../../hooks/useUserReadAccess';
import { CalendarTile } from './CalendarTile';
import { AllStudentsTaskTile } from './AllStudentsTaskTile';
import { MyStudentsTile } from './StudentsTiles/MyStudentsTile';
import { MyLessonRequestsTile } from './MyLessonRequestsTile';
import { DashboardTodoMention } from './DashboardTodoMention';
import { EmployeeBirthdaysTile } from './EmployeeBirthdaysTile';
import { MyStudentsTaskTile } from './MyStudentsTaskTile';
import { MyStudentsWithoutAppointmentTile } from './StudentsWithoutAppointment/MyStudentsWithoutAppointmentTile';
import { AllLessonRequestListTile } from './AllLessonRequestsTile';
import { OverdueInvoicesTile } from './OverdueInvoicesTile';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';
import { TeamStudentsTile } from './StudentsTiles/TeamStudentsTile';
import { AllStudentsWithoutAppointmentTile } from './StudentsWithoutAppointment/AllStudentsWithoutAppointmentTile';
import { TeamStudentsWithoutAppointmentTile } from './StudentsWithoutAppointment/TeamStudentsWithoutAppointmentTile';
import { TeamStudentsTaskTile } from './TeamStudentsTaskTile';
import { MyStudentsPriorityTile } from './MyStudentsPriorityTile';

const dashboardComponentReadPermissions: Record<
  DashboardComponent,
  Permission[] | null
> = {
  [DashboardComponent.MY_DAY]: [Permission.OWN_CALENDAR],
  [DashboardComponent.ALL_STUDENT_TASKS]: [Permission.ALL_STUDENTS_INFO],
  [DashboardComponent.OWN_STUDENT_TASKS]: [Permission.OWN_STUDENTS_INFO],
  [DashboardComponent.OVERDUE_INVOICES]: [Permission.ALL_INVOICES],
  [DashboardComponent.EMPLOYEE_BIRTHDAYS]: null,
  [DashboardComponent.MY_STUDENTS]: [Permission.OWN_STUDENTS_INFO],
  [DashboardComponent.MY_STUDENTS_PRIORITY]: [Permission.OWN_STUDENTS_INFO],
  [DashboardComponent.ALL_STUDENTS_WITHOUT_APPOINTMENT]: [
    Permission.ALL_STUDENTS_INFO,
  ],
  [DashboardComponent.OWN_STUDENTS_INFO_WITHOUT_APPOINTMENT]: [
    Permission.OWN_STUDENTS_INFO,
  ],
  [DashboardComponent.MY_LESSON_REQUESTS]: [Permission.OWN_LESSON_REQUESTS],
  [DashboardComponent.ALL_LESSON_REQUESTS]: [Permission.ALL_LESSON_REQUESTS],
  [DashboardComponent.TODO_COUNT]: [
    Permission.OWN_TODOS,
    Permission.SENT_TODOS,
  ],
  // these manage their own permissions by only showing users of the team
  [DashboardComponent.TEAM_STUDENT_TASKS]: null,
  [DashboardComponent.TEAM_STUDENTS]: null,
  [DashboardComponent.TEAM_STUDENTS_WITHOUT_APPOINTMENT]: null,
};

export const EmployeeDashboard: FunctionComponent = () => {
  const { user } = useAppContext(UserType.EMPLOYEE);
  const hasReadAccess = useUserReadAccess();

  const dashboardComponents = user?.role?.dashboardComponents ?? [];

  function mapDashboardComponent(component: DashboardComponent) {
    if (
      dashboardComponentReadPermissions[component] &&
      !dashboardComponentReadPermissions[component].some((permission) =>
        hasReadAccess(permission),
      )
    ) {
      return <DashboardPermissionMissingHint dashboardComponent={component} />;
    }

    switch (component) {
      case DashboardComponent.EMPLOYEE_BIRTHDAYS:
        return <EmployeeBirthdaysTile />;
      case DashboardComponent.TODO_COUNT:
        return <DashboardTodoMention />;
      case DashboardComponent.MY_DAY:
        return <CalendarTile />;
      case DashboardComponent.ALL_STUDENT_TASKS:
        return <AllStudentsTaskTile />;
      case DashboardComponent.OWN_STUDENT_TASKS:
        return <MyStudentsTaskTile />;
      case DashboardComponent.OVERDUE_INVOICES:
        return <OverdueInvoicesTile />;
      case DashboardComponent.MY_STUDENTS:
        return <MyStudentsTile />;
      case DashboardComponent.MY_STUDENTS_PRIORITY:
        return <MyStudentsPriorityTile />;
      case DashboardComponent.TEAM_STUDENTS:
        return <TeamStudentsTile />;
      case DashboardComponent.ALL_STUDENTS_WITHOUT_APPOINTMENT:
        return <AllStudentsWithoutAppointmentTile />;
      case DashboardComponent.OWN_STUDENTS_INFO_WITHOUT_APPOINTMENT:
        return <MyStudentsWithoutAppointmentTile />;
      case DashboardComponent.TEAM_STUDENTS_WITHOUT_APPOINTMENT:
        return <TeamStudentsWithoutAppointmentTile />;
      case DashboardComponent.MY_LESSON_REQUESTS:
        return <MyLessonRequestsTile />;
      case DashboardComponent.ALL_LESSON_REQUESTS:
        return <AllLessonRequestListTile />;
      case DashboardComponent.TEAM_STUDENT_TASKS:
        return <TeamStudentsTaskTile />;
      /* TODO finance tasks */
      /* TODO hr tasks */
    }
  }

  if (!dashboardComponents?.length) return null;

  return (
    <div
      className={classNames(
        'mb-10 gap-5 space-y-5',
        dashboardComponents.length > 1 && 'lg:columns-2 ',
      )}
    >
      {dashboardComponents.map((component) => (
        <React.Fragment key={component}>
          {mapDashboardComponent(component)}
        </React.Fragment>
      ))}
    </div>
  );
};
