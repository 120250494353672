import React, { FunctionComponent } from 'react';
import sum from 'lodash/sum';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { classNames } from '../../../utilities/classNames';
import { useAppContext, UserType } from '../../../context/appContext';
import { useTimeTrackingUnit } from '../TimeTrackingHelpers';
import { EventFeed } from './EventFeed';
import { WorkEvent } from '@tr-types/backend-types';

interface Props {
  events: WorkEvent[];
  addShadowTime?: boolean;
}

/**
 * List of events grouped by date and month, with sticky headings based on https://tailwindui.com/components/application-ui/lists/stacked-lists#component-f426e5b090a3887f40863034589d365b
 * and the event list as a feed, based on https://tailwindui.com/components/application-ui/lists/feeds#component-a70becc0d3cc548c2205920a6aa78e94
 */
export const EventList: FunctionComponent<Props> = ({
  events,
  addShadowTime,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'eventsList' });
  const { timeTrackingInLessons } = useTimeTrackingUnit();
  const { organization } = useAppContext(UserType.BOTH);

  const groupByFormat = 'YYYY-MM-DD';
  const millisecondsPerMinute = 60000;

  function eventDurationSum(events: WorkEvent[]): Duration {
    const durationSum = sum(
      events.map(
        (d) =>
          dayjs(d.end_time).diff(dayjs(d.start_time)) +
          (addShadowTime && d.billingType!.shadow_time
            ? d.billingType!.shadow_time * millisecondsPerMinute
            : 0),
      ),
    );
    return dayjs.duration(durationSum);
  }

  function getEventGroupString(a: WorkEvent): string {
    return dayjs(a.start_time).format(groupByFormat);
  }

  const compareStartDate = (a: WorkEvent, b: WorkEvent): number => {
    return dayjs(a.start_time)
      .startOf('d')
      .diff(dayjs(b.start_time).startOf('d'));
  };

  function getEventGroups(events: WorkEvent[]): [string, WorkEvent[]][] {
    if (!events) return [];

    const sortedEvents = events.sort(compareStartDate);
    return Object.entries(groupBy(sortedEvents, getEventGroupString));
  }

  function eventsByDate(events: WorkEvent[]) {
    return getEventGroups(events).map(
      ([day, events]: [string, WorkEvent[]]) => {
        const dayEventsDuration = eventDurationSum(events);
        return (
          <div key={day} className="relative py-2">
            <div
              className={classNames(
                'z-10 sticky top-0 rounded-lg pl-6 pr-3 py-1 text-sm font-medium grid grid-cols-2 select-none',
                dayjs(day, groupByFormat).isSameOrAfter(dayjs().startOf('d'))
                  ? 'bg-accent-50 text-accent-600'
                  : 'bg-gray-50 text-gray-600',
              )}
            >
              <h3>{dayjs(day, groupByFormat).format('DD MMM YYYY')}</h3>
              <span className="justify-self-end">
                {timeTrackingInLessons
                  ? round(
                      dayEventsDuration.asMinutes() /
                        organization.lesson_duration_minutes,
                      2,
                    ) +
                    ' ' +
                    t('lessons', {
                      count:
                        dayEventsDuration.asMinutes() /
                        organization.lesson_duration_minutes,
                    })
                  : `${dayEventsDuration.hours()}h ` +
                    (!!dayEventsDuration.minutes()
                      ? `${dayEventsDuration.minutes()}m`
                      : '')}
              </span>
            </div>
            <EventFeed events={events} addShadowTime={addShadowTime} />
          </div>
        );
      },
    );
  }

  return (
    <nav className="py-2" aria-label="Directory">
      {events?.length > 0 ? (
        eventsByDate(events)
      ) : (
        <p className="text-gray-400 font-normal">{t('altText')}</p>
      )}
    </nav>
  );
};
